<template>
  <div class="container layout">
    <div class="form">
      <div class="name layout">靑追国际后台管理系统</div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item prop="userName">
          <el-input @focus="clearValidate('userName')" placeholder="请输入账号" prefix-icon="el-icon-user-solid"
                    v-model="ruleForm.userName"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" show-password @focus="clearValidate('password')" placeholder="请输入密码"
                    prefix-icon="el-icon-s-goods" v-model="ruleForm.password"></el-input>
        </el-form-item>
      </el-form>
      <div class="btn-list">
        <el-button type="primary" @click="submitForm" :loading="loading">{{ loading ? '登录中...' : '登录' }}</el-button>
        <el-button type="info" @click="resetForm">重置</el-button>

      </div>
    </div>
  </div>
</template>

<script>
import {login} from "@/apis/login.js";

export default {
  data() {
    return {
      ruleForm: {
        userName: "",
        password: "",
      },
      rules: {
        userName: [
          {required: true, message: "请输入登录名称", trigger: "blur"},
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
        password: [
          {required: true, message: "请输入登录密码", trigger: "blur"},
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
      loading: false
    };
  },
  created() {
    document.onkeydown = (e) => {
      let key = window.event.keyCode;
      if (key === 13 && !this.state) this.submitForm();
    };
  },
  computed: {
    state() {
      // 表单都填写才触发回车键
      return Object.values(this.ruleForm).some(
          (item) => item === (null || undefined || "")
      );
    },
  },
  methods: {
    // 提交登录
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) return;
        this.loading = true;
        login(this.ruleForm).then(res => {
          window.sessionStorage.setItem("userinfo", JSON.stringify(res.data));
          this.$router.push({path: "/home"});
          setTimeout(() => {
            this.loading = false;
          }, 1000)
        }).catch(err => {
          this.loading = false;
        });
      });
    },
    // 重置按钮
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 获取焦点对表单重新校验
    clearValidate(prop) {
      this.$refs.ruleForm.clearValidate(prop);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100vh;
  widows: 100vw;
  background: url("../assets/background.png");
  background-size: cover;
  color: #000;

  .form {
    position: relative;
    width: 350px;
    background: #fff;
    padding: 20px;
    border-radius: 6px;

    .name {
      font-size: 18px;
      margin: 20px 0;
    }

    .btn-list {
      display: flex;
      justify-content: flex-end;
    }

    /deep/ .el-input__icon {
      color: #666;
    }
  }
}
</style>